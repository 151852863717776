import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { Spin } from "antd";

export default function Protected({ children }) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const authToken = sessionStorage.getItem("authToken");
  const pathName = location.pathname;
  sessionStorage.removeItem("state");
  const initialState = sessionStorage.getItem("state");

  useEffect(() => {
    if (
      !(
        pathName.includes("/login") ||
        pathName.includes("/forgot") ||
        pathName.includes("/set-password") ||
        pathName.includes("/reset-password") ||
        pathName.includes("/")
      )
    ) {
      if (!authToken) {
        setLoader(true);
        setTimeout(() => {
          navigate("/login?sessionExpired=true");
          setLoader(false);
        }, 300);
      }
    }
    if (!initialState && !authToken) {
      if (
        !(
          pathName.includes("/login") ||
          pathName.includes("/forgot") ||
          pathName.includes("/set-password") ||
          pathName.includes("/reset-password")
        )
      ) {
        navigate("/login");
      }
    }
  }, [navigate, authToken]);

  return loader ? <Spin /> : <>{children}</>;
}
