import { useRouteError } from "react-router-dom";
import React from "react";
import { Button, Layout, Result } from "antd";
import { HomeOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const { Content } = Layout;

export default function ErrorPage({
  title = "Oops! Something went wrong",
  showBackButton = true,
  showHomeButton = true,
} = {}) {
  const error = useRouteError();
  console.error(error);
  const handleGoBack = () => {
    window.history.back();
  };

  const handleGoHome = () => {
    window.location.href = "/";
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Content
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Result
          status={error.status}
          title={title}
          subTitle={error.statusText || error.message}
          extra={[
            showBackButton && (
              <Button
                key="back"
                icon={<ArrowLeftOutlined />}
                onClick={handleGoBack}
              >
                Go Back
              </Button>
            ),
            showHomeButton && (
              <Button
                key="home"
                type="primary"
                icon={<HomeOutlined />}
                onClick={handleGoHome}
              >
                Go Home
              </Button>
            ),
          ].filter(Boolean)}
        />
      </Content>
    </Layout>
  );
}
