import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom/client";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { ConfigProvider, Flex } from "antd";
const App = lazy(() => import("./App.jsx"));
const Login = lazy(() => import("./components/pages/Login"));
const ForgotPassword = lazy(() => import("./components/pages/ForgotPassword"));
const Profile = lazy(() => import("./components/pages/Profile.jsx"));
const Customers = lazy(() => import("./components/pages/Customer.jsx"));
const Home = lazy(() => import("./components/pages/Home"));
const LoyaltyDetails = lazy(() =>
  import("./components/pages/LoyaltyDetails.jsx")
);
const TierForm = lazy(() => import("./components/pages/TierForm.jsx"));
const SuperUserList = lazy(() =>
  import("./components/pages/SuperUserList.jsx")
);
const SuperUserAdd = lazy(() => import("./components/pages/SuperUserAdd.jsx"));
const AddEditSmsDetails = lazy(() =>
  import("./components/pages/AddEditSmsDetails.jsx")
);
const SmsDetails = lazy(() => import("./components/pages/SmsDetails.jsx"));
import "./index.css";
import ErrorPage from "./components/templates/ErrorPage";
import AuthLayout from "./components/templates/AuthLayout/index.jsx";

const Branding = lazy(() => import("./components/pages/Branding.jsx"));

const RefuteManagement = lazy(() =>
  import("./components/pages/RefuteManagement.jsx")
);
const TechnicalDetails = lazy(() =>
  import("./components/pages/TechnicalDetails.jsx")
);
const ScanAndEarnAriston = lazy(() =>
  import("./components/pages/ScanAndEarnAriston.jsx")
);
const EditScan = lazy(() => import("./components/pages/EditScan.jsx"));

const Overview = lazy(() => import("./components/pages/Overview.jsx"));
const TeamMangement = lazy(() =>
  import("./components/pages/TeamManagement.jsx")
);
const TeamManagementViewUser = lazy(() =>
  import("./components/pages/TeamManagementViewUser.jsx")
);
const TeamManagementEditRole = lazy(() =>
  import("./components/pages/TeamManagementEditRole.jsx")
);
const CustomEvents = lazy(() => import("./components/pages/CustomEvents.jsx"));
const AddCustomEvents = lazy(() =>
  import("./components/pages/AddCustomEvent.jsx")
);

const Banners = lazy(() => import("./components/pages/Banners.jsx"));
const BannerForm = lazy(() => import("./components/pages/BannerForm.jsx"));

const MallFeed = lazy(() => import("./components/pages/MallFeed.jsx"));
const AddFeedForm = lazy(() => import("./components/pages/AddFeedForm.jsx"));
const AddTagForm = lazy(() => import("./components/pages/AddTagForm.jsx"));
const ViewFeedUsers = lazy(() => import("./components/pages/ViewFeedUsers"));
const DeepLinkManagement = lazy(() =>
  import("./components/pages/DeepLinkManagement.jsx")
);
const DeepLinkForm = lazy(() => import("./components/pages/DeepLinkForm.jsx"));
const IssueManagement = lazy(() =>
  import("./components/pages/IssueManagement.jsx")
);
const ViewIssueManagement = lazy(() =>
  import("./components/pages/ViewIssueManagement.jsx")
);

const ReferralRevamp = lazy(() =>
  import("./components/pages/ReferralRevamp.jsx")
);

const DelightTableView = lazy(() =>
  import("./components/pages/CustomerDelight.jsx")
);

const DelightForm = lazy(() =>
  import("./components/pages/CustomerDelightForm.jsx")
);

import Spin from "antd/es/spin";
const TeamManagementAddUser = lazy(() =>
  import("./components/pages/TeamManagementAddUser.jsx")
);

const TeamManagementAddRole = lazy(() =>
  import("./components/pages/TeamManagementAddRole.jsx")
);
const RewardsManagement = lazy(() =>
  import("./components/pages/RewardManagement")
);
const AddRewardPage = lazy(() => import("./components/pages/AddRewardPage"));
const AddCouponPage = lazy(() => import("./components/pages/AddCouponPage"));

const AddRewardPreviewFormPage = lazy(() =>
  import("./components/pages/AddRewardPreviewFormPage")
);
const AddRewardCodesPage = lazy(() =>
  import("./components/pages/AddRewardCodesPage")
);

const CreateReferralCampaign = lazy(() =>
  import("./components/pages/CreateReferralCampaign.jsx")
);
const CustomerSummary = lazy(() =>
  import("./components/pages/CustomerSummary")
);
const CouponManagement = lazy(() =>
  import("./components/pages/CouponManagement")
);

import routesConstants from "./helper/routesConstants.js";

const StaticPages = lazy(() => import("./components/pages/StaticPages.jsx"));
const AddEditStaticPage = lazy(() =>
  import("./components/pages/StaticPagesAddEdit.jsx")
);

const ViewReferralCampaign = lazy(() =>
  import("./components/pages/ViewReferralCampaign.jsx")
);

const ViewReferralUsers = lazy(() =>
  import("./components/pages/ViewReferralUsers.jsx")
);

const ShopAndWinList = lazy(() =>
  import("./components/pages/ShopAndWinList.jsx")
);

const ShopAndWinCreateEditView = lazy(() =>
  import("./components/pages/ShopAndWinCreateEditView.jsx")
);

const ShopAndWinViewUser = lazy(() =>
  import("./components/pages/ShopAndWinViewUser.jsx")
);

const SpinTheWheel = lazy(() => import("./components/pages/SpinTheWheel.jsx"));
const SpinTheWheelForm = lazy(() =>
  import("./components/pages/SpinTheWheelForm.jsx")
);
const ViewSpinTheWheelCampaign = lazy(() =>
  import("./components/pages/ViewSpinTheWheelCampaign.jsx")
);
const ViewSpinTheWheelUsers = lazy(() =>
  import("./components/pages/ViewSpinTheWheelUsers.jsx")
);

const ViewCompanies = lazy(() =>
  import("./components/pages/ViewCompanies.jsx")
);

const SetPassword = lazy(() => import("./components/pages/SetPassword.jsx"));

const ResetPassword = lazy(() =>
  import("./components/pages/ResetPassword.jsx")
);

const Retailers = lazy(() => import("./components/pages/Retailers.jsx"));

const AddRetailerForm = lazy(() =>
  import("./components/pages/AddRetailerForm.jsx")
);

const AddShopForm = lazy(() => import("./components/pages/AddShopForm.jsx"));

const AddFloorForm = lazy(() => import("./components/pages/AddFloorForm.jsx"));

const AddCategoryForm = lazy(() =>
  import("./components/pages/AddCategoryForm.jsx")
);

const ViewRetailerPage = lazy(() =>
  import("./components/pages/ViewRetailerForm.jsx")
);

const AddCompany = lazy(() => import("./components/pages/AddCompany.jsx"));

const CashbackPayouts = lazy(() =>
  import("./components/pages/CashbackPayouts.jsx")
);

const SurveyList = lazy(() => import("./components/pages/SurveyList.jsx"));
const SurveyCreateEdit = lazy(() =>
  import("./components/pages/SurveyCreateEdit.jsx")
);

const ScratchCard = lazy(() => import("./components/pages/ScratchCard.jsx"));
const ScratchCardForm = lazy(() =>
  import("./components/pages/ScratchCardForm.jsx")
);

const ViewScratchCard = lazy(() =>
  import("./components/pages/ViewScratchCard.jsx")
);

const ViewScratchCardUsers = lazy(() =>
  import("./components/pages/ViewScratchCardUsers.jsx")
);

const QrCampaign = lazy(() => import("./components/pages/QrCampaign.jsx"));

const ViewCampaign = lazy(() => import("./components/pages/ViewCampaign.jsx"));

const ViewQrUsers = lazy(() => import("./components/pages/ViewQrUsers.jsx"));

const CreateQrCampaign = lazy(() =>
  import("./components/pages/CreateQrCampaign.jsx")
);

const SurveyAnalytics = lazy(() =>
  import("./components/pages/SurveyAnalytics.jsx")
);

const Quiz = lazy(() => import("./components/pages/Quiz.jsx"));

const AddQuiz = lazy(() => import("./components/pages/AddQuiz.jsx"));

const ViewQuiz = lazy(() => import("./components/pages/ViewQuiz.jsx"));

const ViewQuizUsers = lazy(() =>
  import("./components/pages/ViewQuizUsers.jsx")
);

const ViewUserCoupon = lazy(() =>
  import("./components/pages/ViewCouponUsers.jsx")
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={
        <AuthLayout>
          <App />
        </AuthLayout>
      }
      errorElement={<ErrorPage />}
    >
      <Route path="" element={<Home></Home>} errorElement={<ErrorPage />} />
      <Route path="login" element={<Login />} errorElement={<ErrorPage />} />
      <Route
        path="set-password"
        element={<SetPassword />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="reset-password"
        element={<ResetPassword />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="forgot"
        element={<ForgotPassword />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="profile"
        element={<Profile />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="customers"
        element={<Customers />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="customers/:username"
        element={<CustomerSummary />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="loyalty-details"
        element={<LoyaltyDetails />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="loyalty-details/add-tier"
        element={<TierForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="loyalty-details/view-tier/:id"
        element={<TierForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="loyalty-details/edit-tier/:id"
        element={<TierForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/super-user"
        element={<SuperUserList />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/add-super-user"
        element={<SuperUserAdd />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="instance-overview"
        element={<Overview />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/team-management"
        element={<TeamMangement />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="sms-details/sms-update"
        element={<AddEditSmsDetails />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="sms-details"
        element={<SmsDetails />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/team-management/user/:id"
        element={<TeamManagementViewUser />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/team-management/edit-role/:id"
        element={<TeamManagementEditRole />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/team-management/add-user"
        element={<TeamManagementAddUser />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="/team-management/add-role"
        element={<TeamManagementAddRole />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="branding"
        element={<Branding />}
        errorElement={<ErrorPage />}
      />
      {/* <Route
        path="custom-events"
        element={<CustomEvents />}
        errorElement={<ErrorPage />}
      /> */}
      <Route
        path="add-event"
        element={<AddCustomEvents />}
        errorElement={<ErrorPage />}
      />
      <Route path="banner" element={<Banners />} errorElement={<ErrorPage />} />
      <Route
        path="banner/add-banner"
        element={<BannerForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="banner/edit-banner/:id"
        element={<BannerForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="technical-details"
        element={<TechnicalDetails />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed"
        element={<MallFeed />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/add-feed"
        element={<AddFeedForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/edit-feed/:id"
        element={<AddFeedForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/users/:id"
        element={<ViewFeedUsers />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/add-tag"
        element={<AddTagForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/edit-tag/:id"
        element={<AddTagForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed"
        element={<MallFeed />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/add-feed"
        element={<AddFeedForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/edit-feed/:id"
        element={<AddFeedForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/users/:id"
        element={<ViewFeedUsers />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/add-tag"
        element={<AddTagForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="mall-feed/edit-tag/:id"
        element={<AddTagForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="deep-link"
        element={<DeepLinkManagement />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="deep-link/create-deep-link"
        element={<DeepLinkForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="deep-link/view-deep-link/:id"
        element={<DeepLinkForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="referral-campaign-v2"
        element={<ReferralRevamp />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="referral-campaign-v2/create"
        element={<CreateReferralCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="referral-campaign-v2/edit/:id"
        element={<CreateReferralCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="referral-campaign-v2/clone/:id"
        element={<CreateReferralCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="referral-campaign-v2/view/:id"
        element={<ViewReferralCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="referral-campaign-v2/view-users/:id"
        element={<ViewReferralUsers />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="scan-and-earn-ariston"
        element={<ScanAndEarnAriston />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="scan-and-earn-ariston/edit"
        element={<EditScan />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="customer-scans"
        element={<RefuteManagement />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="customer-scans/edit"
        element={<EditScan />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={routesConstants.STATIC_PAGE}
        element={<StaticPages />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={routesConstants.STATIC_PAGE}
        element={<StaticPages />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={routesConstants.ADD_STATIC_PAGE}
        element={<AddEditStaticPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.EDIT_STATIC_PAGE}/:id`}
        element={<AddEditStaticPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="issue-management"
        element={<IssueManagement />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="issue-management/view/:id"
        element={<ViewIssueManagement />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="reward-management"
        element={<RewardsManagement />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="reward-management/add-reward"
        element={<AddRewardPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="reward-management/view-reward/:id"
        element={<AddRewardPreviewFormPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="reward-management/add-codes/:id"
        element={<AddRewardCodesPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="reward-management/edit-reward/:id"
        element={<AddRewardPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="customer-delight"
        element={<DelightTableView />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="customer-delight/add"
        element={<DelightForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={routesConstants.SHOP_AND_WIN}
        element={<ShopAndWinList />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={routesConstants.SHOP_AND_WIN_CREATE_CAMPAIGN}
        element={<ShopAndWinCreateEditView />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SHOP_AND_WIN_EDIT_CAMPAIGN}/:id`}
        element={<ShopAndWinCreateEditView />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SHOP_AND_WIN_VIEW_CAMPAIGN}/:id`}
        element={<ShopAndWinCreateEditView />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SHOP_AND_WIN_VIEW_USER}/:id`}
        element={<ShopAndWinViewUser />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SPIN_THE_WHEEL}`}
        element={<SpinTheWheel />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SPIN_THE_WHEEL_CREATE_CAMPAIGN}`}
        element={<SpinTheWheelForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SPIN_THE_WHEEL_EDIT_CAMPAIGN}/:id`}
        element={<SpinTheWheelForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SPIN_THE_WHEEL_CLONE_CAMPAIGN}/:id`}
        element={<SpinTheWheelForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SPIN_THE_WHEEL_VIEW_CAMPAIGN}/:id`}
        element={<ViewSpinTheWheelCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SPIN_THE_WHEEL_VIEW_USERS}/:id`}
        element={<ViewSpinTheWheelUsers />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="customer-delight/add"
        element={<DelightForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="view-companies"
        element={<ViewCompanies />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="retailers"
        element={<Retailers />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="retailers/add"
        element={<AddRetailerForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="retailers/edit/:retailerId"
        element={<AddRetailerForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="retailers/view/:retailerId"
        element={<ViewRetailerPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="shopNumber/add"
        element={<AddShopForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="shopNumber/edit/:shopId"
        element={<AddShopForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="shopNumber/view/:shopId"
        element={<AddShopForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="floor/add"
        element={<AddFloorForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="floor/edit/:floorId"
        element={<AddFloorForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="category/add"
        element={<AddCategoryForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="category/edit/:categoryId"
        element={<AddCategoryForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="category/view/:categoryId"
        element={<AddCategoryForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="add-company"
        element={<AddCompany />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="cashback-payouts"
        element={<CashbackPayouts />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={routesConstants.SURVEY}
        element={<SurveyList />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={routesConstants.SURVEY_CREATE}
        element={<SurveyCreateEdit />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SURVEY_EDIT}/:id`}
        element={<SurveyCreateEdit />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SCRATCH_CARD}`}
        element={<ScratchCard />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SCRATCH_CARD_CREATE_CAMPAIGN}`}
        element={<ScratchCardForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SCRATCH_CARD_EDIT_CAMPAIGN}/:id`}
        element={<ScratchCardForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SCRATCH_CARD_CLONE_CAMPAIGN}/:id`}
        element={<ScratchCardForm />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SCRATCH_CARD_VIEW_CAMPAIGN}/:id`}
        element={<ViewScratchCard />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SCRATCH_CARD_VIEW_USERS}/:id`}
        element={<ViewScratchCardUsers />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="qr-campaign"
        element={<QrCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="qr-campaign/create"
        element={<CreateQrCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="qr-campaign/view-campaign/:id"
        element={<ViewCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="qr-campaign/view-users/:id"
        element={<ViewQrUsers />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="qr-campaign/edit-campaign/:id"
        element={<CreateQrCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="qr-campaign/clone-campaign/:id"
        element={<CreateQrCampaign />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="qr-campaign/add-more-codes/:id"
        element={<ViewCampaign addMoreCode />}
        errorElement={<ErrorPage />}
      />
      <Route
        path={`${routesConstants.SURVEY_ANALYTICS}/:id`}
        element={<SurveyAnalytics />}
        errorElement={<ErrorPage />}
      />
      <Route path="quiz" element={<Quiz />} errorElement={<ErrorPage />} />
      <Route
        path="quiz/create"
        element={<AddQuiz />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="quiz/view-quiz/:quizId"
        element={<ViewQuiz />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="quiz/view-users/:quizId"
        element={<ViewQuizUsers />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="quiz/edit-quiz/:campaignId"
        element={<AddQuiz />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="quiz/clone-quiz/:campaignId"
        element={<AddQuiz />}
        errorElement={<ErrorPage />}
      />
      <Route path="coupon-management-pro" element={<CouponManagement />} />
      <Route
        path="coupon-management-pro/add-coupon"
        element={<AddCouponPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="coupon-management-pro/view-coupon/:id"
        element={<AddRewardPreviewFormPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="coupon-management-pro/add-codes/:id"
        element={<AddRewardCodesPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="coupon-management-pro/edit-coupon/:id"
        element={<AddCouponPage />}
        errorElement={<ErrorPage />}
      />
      <Route
        path="coupon-management-pro/view-user/:id"
        element={<ViewUserCoupon />}
        errorElement={<ErrorPage />}
      />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#3451b2",
          colorInfo: "#3451b2",
          colorWarning: "#d6a35c",
          colorError: "#e5484d",
          colorTextBase: "#211f26",
          colorSuccess: "#52c41a",
        },
      }}
    >
      <Suspense fallback={<Spin fullscreen />}>
        <Flex className="h-screen">
          <RouterProvider router={router} />
        </Flex>
      </Suspense>
    </ConfigProvider>
  </React.StrictMode>
);
